import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  FlightOperationalAttribute,
  Journey,
  TransportationDesignator
} from '@navitaire-digital/nsk-api-4.5.0';
import { ExtrasManagerStore } from '../../extras';
import { MyTripsService } from '../my-trips.service';

@Component({
  selector: 'navitaire-digital-my-trip',
  templateUrl: './my-trip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['my-trip.scss']
})
export class MyTripComponent implements OnInit {
  @Input() journey: Journey;
  @Input() flightStatus?: FlightOperationalAttribute;
  @Input() designator: TransportationDesignator;
  @Input() overrideDeparture?: string;
  @Input() overrideArrival?: string;
  @Input() stops: number;
  @Input() duration: { hours: number; minutes: number };
  @Input() identifiers: string[];
  @Input() hasMultipleOperators: boolean = false;
  @Input() hasMultipleCarriers: boolean = false;

  FlightStatus: typeof FlightOperationalAttribute = FlightOperationalAttribute;
  IsHasPaxForManage: boolean = false; 
  
  constructor(
    protected extrasManagerStore: ExtrasManagerStore,
    protected myTripsService: MyTripsService
  ){}

  ngOnInit(){
    this.IsHasPaxForManage = this.myTripsService.isHasPaxToManage(this.journey);
  }

}
