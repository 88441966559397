import { Journey, journeysToSegments, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import type { Dictionary } from 'lodash';
import { PassengerServiceSelection } from '../../../models/passenger-service-selection.model';
import { getSsrName } from '../ssrs/common/get-ssr-name';

export function createPassengerServiceSelectionLabels(
  selectedJourneyKey: string,
  selectedPassengerKey: string,
  soldPassengerService: PassengerServiceSelection,
  ssrsResource: Dictionary<Ssr>,
  journeys: Journey[]
): string[] {
  const filteredPassengerService: string[] = [];

  if (!soldPassengerService) {
    return [];
  }

  if (selectedJourneyKey == undefined){
    return [];
  }

  let selectedSegmentKeys = [''];
  journeys.forEach(journey => {
    if (journey.journeyKey === selectedJourneyKey) {
      selectedSegmentKeys = journeysToSegments([journey]).map(segment => segment.segmentKey);
    }
  });

  selectedSegmentKeys.forEach(segmentKey => {
    if (soldPassengerService) {
      if (!soldPassengerService.journeys[selectedJourneyKey].segments[segmentKey]) {
        return null;
      }
      const passengerServices = soldPassengerService.journeys[selectedJourneyKey].segments[segmentKey].passengers[selectedPassengerKey];

      if (passengerServices && passengerServices.length > 0) {
        passengerServices.forEach(passengerService => {
          const passengerServiceName = getSsrName(passengerService, ssrsResource);
          filteredPassengerService.push(passengerServiceName);
        });
      }
    }
  });

  return filteredPassengerService;
}
