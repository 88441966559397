import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavitaireDigitalCommonModule } from '../../common/common.module';
import { FlightStatusModule } from '../../flight-status/flight-status.module';
import { MyTripComponent } from './my-trip.component';
import { ExtrasManagerStore } from '../../extras';

@NgModule({
  declarations: [MyTripComponent],
  imports: [CommonModule, NavitaireDigitalCommonModule, FlightStatusModule],
  providers: [ExtrasManagerStore],
  exports: [MyTripComponent]
})
export class MyTripComponentModule {}
