<div 
  class="insurance-card" 
  [class.selected]="selected"
  (click)="onSelect()"
>
  <div class="checkbox">
    <input type="checkbox" [checked]="selected" />
  </div>
  <div class="content">
    <div class="price">
      <strong>{{ price }}</strong>
      <span class="title">- {{ title }}</span>
    </div>
    <p class="description">{{ description }}</p>
  </div>
</div>