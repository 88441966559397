<div cdkTrapFocus class="seat-info-container">
  <div class="seat-select-header-container">
    <h3 class="seat-info-header text-mobile-1" translate>
    Manage Booking Seat
    </h3>
  <button
      class="navitaire-digital-button medium close-dialog"
      (click)="closeDialog.emit()"
      data-cy="closeExitRowDialog"
      [attr.arial-label]="'Cancel selection' | translate"
      tabindex="0"
      translate
    >
      X
    </button>
    </div>
  <div class="seat-select-option-container">
    <h5>
      Oops.. You cannot cancle this seat. Only Change seat can be allowed
    </h5>
  </div>
</div>
