import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsLinkGroupModule } from '../cms/cms-components/link-group/cms-link-group.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { MobilePopUpHeaderModule } from '../layout/headers/mobile-popup-header/mobile-popup-header.module';
import { ModalModule } from '../layout/modal/modal.module';
import { BaseGridDirective } from './directives/base-grid.directive';
import { SeatmapGridComparmentDirective } from './directives/compartment-grid.directive';
import { UnitAvailabilityClassesDirective } from './directives/unit-availability-classes.directive';
import { SeatmapUnitDirective } from './directives/unit-grid.directive';
import { UnitLabelGridDirective } from './directives/unit-label-grid.directive';
import { GetUnitPrice } from './pipes/get-unit-price.pipe';
import { IsUnitAvailablePipe } from './pipes/is-unit-available.pipe';
import { PassengerKeyToNameWithLastInitialPipe } from './pipes/passengerKeyToNameWithLastInitial.pipe';
import { SortSeatmapCompartments } from './pipes/sort-compartments.pipe';
// eslint-disable-next-line max-len
//import { ExitRowSeatConfirmationDialogComponent } from './seat-dialogs/exit-row-seat-confirmation-dialog/exit-row-seat-confirmation-dialog.component';
import { SeatmapComponent } from './seatmap.component';
import { StatelessSeatmapComponent } from './stateless-seatmap/stateless-seatmap.component';
import { CancelSeatNotAllowedDialogComponent, ExitRowSeatConfirmationDialogComponent } from './seat-dialogs';

@NgModule({
  imports: [
    CommonModule,
    ScrollingModule,
    NavitaireDigitalCommonModule,
    MobilePopUpHeaderModule,
    ModalModule,
    CmsLinkGroupModule
  ],
  exports: [
    SeatmapComponent,
    SeatmapUnitDirective,
    UnitAvailabilityClassesDirective,
    SortSeatmapCompartments,
    SeatmapGridComparmentDirective,
    GetUnitPrice,
    UnitLabelGridDirective,
    IsUnitAvailablePipe,
    PassengerKeyToNameWithLastInitialPipe,
    ExitRowSeatConfirmationDialogComponent,
    CancelSeatNotAllowedDialogComponent,
    StatelessSeatmapComponent
  ],
  declarations: [
    SeatmapComponent,
    SeatmapUnitDirective,
    UnitAvailabilityClassesDirective,
    SortSeatmapCompartments,
    SeatmapGridComparmentDirective,
    GetUnitPrice,
    IsUnitAvailablePipe,
    PassengerKeyToNameWithLastInitialPipe,
    ExitRowSeatConfirmationDialogComponent,
    CancelSeatNotAllowedDialogComponent,
    UnitLabelGridDirective,
    BaseGridDirective,
    StatelessSeatmapComponent
  ]
})
export class SeatMapModule {}
