import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  asPromise,
  CheckinRequirements,
  FlightOperationalAttribute,
  Journey,
  journeysToLegs,
  Passenger,
  SeatAssignmentMode,
  AutoAssignRequest,
  LiftStatus,
  SimpleSeatPreference,
  TravelClassSeatPreference,
  BookingCommentRequest,
  CommitRequestv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  LegDetailsDataService,
  TripDataService,
  SeatDataService,
  BookingDataService,
  NgBookingClientService,
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { PageAction } from '../../../analytics/actions/page/page-action';
import { PageEventType } from '../../../analytics/models/page-event-type';
import { FlowManagerService } from '../../../app-state/flow-manager.service';
import { NavitaireDigitalOverlayService } from '../../../common/overlay.service';
import { PageBusyService } from '../../../common/page-busy.service';
import { FlightStatusService } from '../../../flight-status/services/flight-status.service';
import { NskCheckinSelectors } from '../../../store/check-in/checkin.selectors';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { selectAllowTravelDocs } from '../../../config/selectors';
import { CheckinRequirementsService } from '../../../checkin/services/checkin-requirements.service';
import { CompleteCheckinAction } from '../../../analytics/actions/check-in/complete-checkin-action';
import { ModalComponent } from '../../../cms/cms-components/modal/modal.component';
import { QGCheckinDataService } from '@customer/extensions';
import { ClearBoardingPasses, SetBoardingPasses } from '../../../store/actions';
import { SetCurrentFlow } from '../../../store';
import { ExternalAPICommonService } from 'projects/extensions/src/lib';

@Component({
  selector: 'navitaire-digital-review-page',
  templateUrl: './review-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['review-page.scss']
})
export class ReviewPageComponent implements OnInit, OnDestroy {
  title: string = 'Check-In and Boarding Pass';
  /** Checkin requirements */
  checkinRequirements$: Observable<CheckinRequirements> =
    this.checkinDataService.checkinRequirements$;

  /** Boolean for if adding travel documents is allowed */
  allowTravelDocuments: boolean = getObservableValueSync(
    this.store.select(selectAllowTravelDocs)
  );

  /** Initial validity state of checkin requirements */
  initiallyValid: boolean;

  /** Boolean for if checkin requirements are complete */
  checkinComplete: boolean = false;

  /** Boolean for booking is International */
  isInternational: boolean = false;

  tacCheckBoxValue: boolean

  journeyToCheckInKeys: string [] = [];

  /** Journeys that are checkinable in the booking */
  checkinableJourneys$: Observable<Journey[]> = this.store.select(NskCheckinSelectors.selectNextCheckinableJourneys);

  /** Journeys that are checkinable in the booking */
  bookingJourneys$: Observable<Journey[]> = this.store.select(NskCheckinSelectors.selectAllJourneys);
  departingJourney$: Observable<Journey> = this.bookingJourneys$.pipe(
    map(journeys => (journeys && journeys.length ? journeys[0] : undefined))
  )
  returningJourney$: Observable<Journey> = this.bookingJourneys$.pipe(
    map(journeys => (journeys && journeys.length ? journeys[1] ?? undefined : undefined))
  )

  legKeysOnFirstCheckinJourney$: Observable<string[]> =
    this.departingJourney$.pipe(
      filter(journey => !!journey),
      map(journey =>
        journey.segments
          .map(segment => segment.legs.map(leg => leg.legKey))
          .reduce((acc, cur) => acc.concat(cur), [])
      )
    );

  legKeysOnSecondCheckinJourney$: Observable<string[]> =
    this.returningJourney$.pipe(
      filter(journey => !!journey),
      map(journey =>
        journey.segments
          .map(segment => segment.legs.map(leg => leg.legKey))
          .reduce((acc, cur) => acc.concat(cur), [])
      )
    );

  flightStatusForDepartingJourney$: Observable<FlightOperationalAttribute>;
  flightStatusForReturningJourney$: Observable<FlightOperationalAttribute>;


  /** Check in selections */
  selectCheckinSelections$ = this.store.select(NskCheckinSelectors.selectCheckinSelections);

  /** Number of passengers to be checked in */
  passengersLength: number;

  /** List of passengers to be checked in */
  passengers: Passenger[];

  // Component destroyed subject
  unsubscribe$ = new Subject<void>();

  @ViewChild('travelDocErrorModal')
  travelDocErrorModal: ModalComponent;

  @ViewChild('dangerousGoodsModal')
  dangerousGoodsModal: ElementRef;

  /** Emits when checkinRquirements are valid */
  @Output() checkinRequirementsComplete: EventEmitter<void> =
    new EventEmitter<void>();

  /** Emits when checkin fails and should bed direct home */
  @Output() startOver: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected checkinDataService: QGCheckinDataService,
    protected flightStatusService: FlightStatusService,
    protected legDetailsDataService: LegDetailsDataService,
    protected flowManagerService: FlowManagerService,
    protected pageBusyService: PageBusyService,
    protected overlayService: NavitaireDigitalOverlayService,
    protected router: Router,
    protected store: Store,
    protected tripDataService: TripDataService,
    protected checkinRequirementsService: CheckinRequirementsService,
    protected bookingDataService: BookingDataService,
    protected seatDataService: SeatDataService,
    protected bookingClient: NgBookingClientService,
    protected externalAPICommonService: ExternalAPICommonService,
  ) { }

  async ngOnInit(): Promise<void> {   
    
    this.store.dispatch(
      PageAction({
        event: PageEventType.CheckInReview
      }) 
    );

    //checks if booking is international
    this.isInternational = getObservableValueSync(this.departingJourney$).segments
      .some(segment => segment?.international)

    this.checkinRequirements$
      .pipe(
        filter(checkinRequirements => !!checkinRequirements),
        take(1)
      )
      .subscribe(checkinRequirements => {
        this.initiallyValid =
          checkinRequirements.isValid &&
          !checkinRequirements.governmentProgramRequirements['DOCCHECK'];
      });

    this.tripDataService.passengers$
      .pipe(
        filter(passengers => !!passengers),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(passengers => {
        this.passengers = passengers;
        this.passengersLength = passengers.length;
      });

    await this.getJourneysFlightStatus();
    
    this.store.dispatch(
      SetCurrentFlow({ currentFlow: 'WCI' })
    );
  }

  /**
   * Components on destory
   * Used to unsubscribe from all subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async autoAssignSeats():Promise<void>{
    try{
      let journeyKeys: string[];
      const journeyPassengerSelections = this.checkinDataService.journeyPassengerSelections;
      const journeys = getObservableValueSync(this.checkinDataService.journeys$);
      let seatAssigned = false;
      journeyKeys = journeys?.map((journey) => journey?.journeyKey);
  
        if (!journeyKeys) {
          return;
        }

        for (const key of journeyKeys) {  
          
          if (await this.hasSeats(key)) {
            //If journey and pax has seats continue on the next journey
            continue;
          }   
          
          const passengerKeys =
          journeyPassengerSelections.filter((j)=>j.journeyKey === key).map(
            (p) => p.passengerKey 
          ).filter((p) => !this.isPassengerCheckedIn(key,p));

          if(passengerKeys !== null && passengerKeys.length > 0){
            const firstPaxKey: string = passengerKeys[0];
            const autoAssignRequest: AutoAssignRequest = {
              waiveFee: true,
              seatedNearPrimary: passengerKeys.length === 1 ? passengerKeys : passengerKeys.filter((paxKey) => paxKey !== firstPaxKey),
              seatAssignmentMode: SeatAssignmentMode.WebCheckIn,
              preferences: {
                seat : SimpleSeatPreference.None,
                travelClass: TravelClassSeatPreference.None
              }
            }
            await this.autoAssign(key,firstPaxKey,autoAssignRequest);
            seatAssigned = true;
          }
        }

        if(seatAssigned){
          await this.bookingDataService.commitBooking();
        }    
    } catch (e){
      this.pageBusyService.hideLoadingSpinner();
    }
  }

    /** Auto assign. */
    // No need to delete existing seats
    // Will not assign seat to pax with seat
    async autoAssign(
      journeyKey: string,
      primaryPassengerKey: string,
      autoAssignRequest: AutoAssignRequest
    ): Promise<void> {
      await this.seatDataService.autoAssignSeats(
        journeyKey,
        primaryPassengerKey,
        autoAssignRequest
      );
    }

    // Check if all passenger has seats
    async hasSeats(journeyKey: string): Promise<boolean> {
      const journeys = getObservableValueSync(this.checkinDataService.journeys$);
      if (!journeys) {
        return false;
      }
      const segment = journeys.filter((j) => j.journeyKey === journeyKey)[0]
        ?.segments[0];
      const seats = getObservableValueSync(this.seatDataService.seats$);
      let isAllPaxAssignedSeats = false;
      if (segment && seats) {
        isAllPaxAssignedSeats =
          Object.values(seats.segments).filter(
            (s) =>
              // Check if all passenger has seats
              s.segmentKey === segment.segmentKey &&
              Object.values(s.passengers).filter(
                (p) => !p.seats || !p.seats.length 
              ).length === 0
          ).length > 0;
      }
  
      if (isAllPaxAssignedSeats) {
        return true;
      }
  
      return false;
    }

    // async saveBookingChange(
    // ): Promise<void> {
    //   const commitRequest: CommitRequestv2 = {};

    //   await this.bookingClient.nsk_v3_booking_put(false, commitRequest);
    // }

    
  /** This gets the passenger keys of the checked in passengers
  */
  isPassengerCheckedIn(journeyKey: string, passengerKey: string): boolean {
    const journeys = getObservableValueSync(this.checkinDataService.journeys$);
    const journey:Journey = journeys.find((journey) => journey.journeyKey == journeyKey);
    var passengerSegments = journey.segments.flatMap(segment => {
      return Object.values(segment?.passengerSegment)
        .filter(passengerSegment => passengerSegment.liftStatus === LiftStatus.CheckedIn)
        .filter(passengerSegment => passengerSegment?.passengerKey === passengerKey);
    });

    return passengerSegments.length > 0 ? true : false;
  }

  async complete(): Promise<void> {
    this.pageBusyService.showLoadingSpinner();
    if (this.checkinComplete || this.initiallyValid) {
      await this.autoAssignSeats();
      // await this.saveBookingChange();
      await this.checkinSelectedPassengers();
      return;
    }
    const checkinRequirements = await asPromise(this.checkinRequirements$);
    let valid: boolean;

    if (this.allowTravelDocuments) {
      const passengerKeys = this.passengers.map(p => p.passengerKey);
      valid =
        await this.checkinRequirementsService.verifyDocumentsAndCheckinRequirements(
          checkinRequirements,
          passengerKeys
        );
    } else {
      valid = checkinRequirements.isValid;
    }
    if (valid) {
      this.checkinComplete = true;
      await this.checkinSelectedPassengers();
    } else {
      this.pageBusyService.hideLoadingSpinner();
      this.showTravelDocError();
    }
    this.pageBusyService.hideLoadingSpinner();
  }

  async putBooking(): Promise<void> {
    const commitRequest: CommitRequestv2 = {
      hold: null,
      notifyContacts: false,
      restrictionOverride: false
    };

    try {
      commitRequest.comments = await this.writeBookingComment();

      await this.bookingClient.nsk_v3_booking_put(true, commitRequest);
    } catch (err) {
      console.log(err);
    }
  }

  async writeBookingComment(): Promise<BookingCommentRequest[]>{
    const bcArray: BookingCommentRequest[] = [];
    const currentDateTime = new Date().toISOString();
    const IP = await this.externalAPICommonService.getClientIp();
    
    const clientIPdata :  BookingCommentRequest = {
      createdDate: currentDateTime,
      sendToBookingSource: true,
      text: "CHECKIN IP: " + IP,
      type: 0,
    }

    bcArray.push(clientIPdata);

    return bcArray
  };

  async getJourneysFlightStatus() {
    //for Departing Journey
    const legKeysOnFirstCheckinJourney = await asPromise(
      this.legKeysOnFirstCheckinJourney$
    );
    this.legDetailsDataService.retrieveLegDetails(legKeysOnFirstCheckinJourney);
    const legDetailsOnFirstCheckinJourney = this.legDetailsDataService.legDetails$;

    this.flightStatusForDepartingJourney$ = combineLatest([
      legDetailsOnFirstCheckinJourney,
      this.legKeysOnFirstCheckinJourney$,
      this.departingJourney$,
      this.store.select(BookingSelectors.selectBooking)
    ]).pipe(
      filter(
        ([legDetails, legKeys, journey, booking]) => !!legDetails && !!legKeys && !!journey && !!booking
      ),
      map(([legDetails, legKeys, journey, booking]) => {
        const filteredLegDetails = Object.entries(legDetails)
          .filter(([legKey, _]) => legKeys.includes(legKey))
          .map(([_key, value]) => value);
        const legs = journeysToLegs([journey]);
        return this.flightStatusService.calculateStatus(
          filteredLegDetails,
          legs,
          booking
        );
      })
    );

    // for Returning journey
    if (getObservableValueSync(this.returningJourney$)) {
      this.legDetailsDataService.clearLegDetails();
      const legKeysOnSecondCheckinJourney = await asPromise(
        this.legKeysOnSecondCheckinJourney$
      );
      this.legDetailsDataService.retrieveLegDetails(legKeysOnSecondCheckinJourney);
      const legDetailsOnSecondCheckinJourney = this.legDetailsDataService.legDetails$;

      this.flightStatusForReturningJourney$ = combineLatest([
        legDetailsOnSecondCheckinJourney,
        this.legKeysOnSecondCheckinJourney$,
        this.returningJourney$,
        this.store.select(BookingSelectors.selectBooking)
      ]).pipe(
        filter(
          ([legDetails, legKeys, journey, booking]) => !!legDetails && !!legKeys && !!journey && !!booking
        ),
        map(([legDetails, legKeys, journey, booking]) => {
          const filteredLegDetails = Object.entries(legDetails)
            .filter(([legKey, _]) => legKeys.includes(legKey))
            .map(([_key, value]) => value);
          const legs = journeysToLegs([journey]);
          return this.flightStatusService.calculateStatus(
            filteredLegDetails,
            legs,
            booking
          );
        })
      );
    }

  }

  openDangerousGoodsModal(): void {
    this.overlayService.show(this.dangerousGoodsModal);
  }

  goCheckinHome(): void {
    this.router.navigate([`/home/checkin`]);
  }

  /**
  * Checks in passengers for the next flight, it assumes the checkin selection
  * has been set during PNR retrieval.
  */
  async checkinSelectedPassengers(): Promise<void> {
    const distinctJourneyKeys = [...new Set(this.checkinDataService.journeyPassengerSelections
      ?.map(journeyPassengerSelection => journeyPassengerSelection?.journeyKey))];

    if (distinctJourneyKeys?.length > 0) {
      await Promise.all(distinctJourneyKeys
        .filter(journeyKey => journeyKey)
        .map(journeyKey => this.pageBusyService.setAppBusyPromise(
          this.checkinDataService.checkinPassengersForJourneyAndGetBoardingPasses(
            journeyKey,
            true
          )
        ))
      );

      const checkedinPassengersBoardingPasses = await this.checkinDataService.generateBoardingPasses(getObservableValueSync(this.bookingJourneys$));
      
      if (checkedinPassengersBoardingPasses) {
        this.store.dispatch(ClearBoardingPasses());
        this.store.dispatch(
          SetBoardingPasses({ boardingPasses: checkedinPassengersBoardingPasses })
        );
      }
     
      this.pageBusyService.hideLoadingSpinner();
      this.store.dispatch(CompleteCheckinAction());
      this.putBooking();
      this.router.navigate([this.flowManagerService.nextUrl()]);
    }
  }

  showTravelDocError(): void {
    this.travelDocErrorModal.show();
    asPromise(this.travelDocErrorModal.onConfirmClick).then(() =>
      this.startOver.emit()
    );
  }
}
