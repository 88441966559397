import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {
  BaseAppAnalyticsService,
  CdkFlightSelectActions,
  DeepLinkHandlerService,
  MobileWebViewMessageType,
  MobileWebViewService,
  WINDOW
} from '@customer/components';
import { COMPANY_WEBSTITE_URL, QGAvailabilityDataService } from '@customer/extensions';
import {  NskAvailabilityActions } from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkGuard implements CanActivate {
  
  constructor(
    protected router: Router,
    @Optional() protected webViewService: MobileWebViewService,
    protected appAnalytics: BaseAppAnalyticsService,
    //protected availabilityDataService: AvailabilityDataService,
    protected availabilityDataService: QGAvailabilityDataService,
    protected deepLinkHandlerService: DeepLinkHandlerService,
    @Inject(PLATFORM_ID) protected platform: Object,
    protected store: Store,
    @Optional() @Inject(WINDOW) protected window: Window,
    @Inject(COMPANY_WEBSTITE_URL) protected companyWebUrl: string
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platform)) {
      return true;
    }
    return this.handleDeepLinkParams(state);
  }

  /**
   * Handle the query params and decide where to navigate to
   */
  async handleDeepLinkParams(
    state: RouterStateSnapshot
  ): Promise<UrlTree | boolean> {
    try {
      this.store.dispatch(CdkFlightSelectActions.clearjourneyselections());
      const request = await this.deepLinkHandlerService.setupTransfer()
      if (request) {
        this.availabilityDataService.saveAvailabilityRequest(request);
      }

      if (this.deepLinkHandlerService.searchParamValue) {
        this.store.dispatch(NskAvailabilityActions.clearlowfares());
        await this.availabilityDataService.fetchAvailabilityWithSsr(request);
        if (!state.url.includes('booking/select')) {
          return this.router.createUrlTree(['booking/select'], {});
        }
      }
        if (state.url.includes('transfer/agentLogin')) {
        return true;
      } else {
        if (state.url == '/home/search') {
          //return this.router.createUrlTree(['home/search']);
          this.window.location.href = this.companyWebUrl;
          return false;
        }
        // if (this.window) {
        //   this.window.location.href = this.companyWebUrl;
        //   return false;
        // }
      }

      return true;
    } catch (err) {
      return this.handleTransferError(err);
    }
  }

  /**
   * Handle errors related to the transfer
   */
  handleTransferError(err: Error | any): UrlTree {
    this.appAnalytics.trackError('MobileLoadingComponent:error', {
      error: err
    });
    let statusCode = null;

    if (err && err.errors && err.errors[0] && err.errors[0].statusCode) {
      statusCode = err.errors[0].statusCode;
    } else if (err.statusCode) {
      statusCode = err.statusCode;
    }
    if (
      statusCode &&
      (statusCode === 401 || statusCode === 440) &&
      this.webViewService
    ) {
      const message = this.webViewService.makeMobileWebViewMessage(
        MobileWebViewMessageType.TokenError,
        `statusCode: ${err.statusCode}, ${err.message}`
      );
      this.webViewService.invokeAction(message);
    } else {
      return this.router.createUrlTree(['error/global', {}]);
    }
  }
}
