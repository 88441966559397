import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ExternalAPICommonService {
    constructor(
        protected http: HttpClient,
    ) {}

      async getClientIp(): Promise<string> {
        try{
          const response = await fetch("https://geolocation-db.com/json/")
          const data = await response.json();
          
          return data.IPv4
          
        }catch(ex){
          console.log(`External API error: ${ex}`);
          return '';
        }
      }
}