import { PassengerFee, Ssr } from '@navitaire-digital/nsk-api-4.5.0';
import { BookingSummaryItem } from '../models';
import { Dictionary } from 'lodash';
//import { ServiceChargeType } from '@navitaire-digital/web-data-4.5.0';

export function getDetailSSR(passengerFee: PassengerFee, object: BookingSummaryItem[], ssrDictionary: Dictionary<Ssr>) {
    const hasIndex = object.findIndex(i => i.code == passengerFee.ssrCode);
    
    passengerFee.serviceCharges.map(sd => 
        {
            if(hasIndex < 0 && sd.type == 6){
                var ssrDetail  = ssrDictionary[passengerFee.ssrCode];
                const data: BookingSummaryItem = { name: ssrDetail.name, amount: sd.amount, count: 1, code: passengerFee.ssrCode };
                object.push(data);
            }else{
                object.filter(x => x.code == passengerFee.ssrCode).map(
                    y => {
                        if(sd.type == 6) {
                            y.amount += sd.amount;
                            y.count ++
                        }else {
                            y.amount += sd.amount;
                        }
                    });
            }
        }
    );

    return object;
}


	
	
	
	
	
	
	
	
	


	
	
	
	
	


	
	
	
	

