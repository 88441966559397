import { Location } from '@angular/common';
import { Component, ViewEncapsulation,OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import {
   SsrByKeysRequestv2,SsrByKeyRequest
   } from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  SsrDataService,
  TripDataService
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import { Observable, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtrasManagerStore } from '../../../extras/extras-manager/extras-manager-component.store';
import { PageBusyService } from '../../../common/page-busy.service';

@Component({
  selector: 'navitaire-digital-select-wrappers-page',
  templateUrl: './select-wrappers-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['select-wrappers.scss']
})
export class SelectWrappersPageComponent implements OnInit, OnDestroy {
  currencyCode: string =  this.tripDataService.currencyCode;
  
  isLastLeg: boolean;
  unsubscribe$ = new Subject<void>();

  hasOneJourney$: Observable<boolean> =
    this.extrasManagerStore.selectHasOneJourney$;

  isLastJourney$: Observable<boolean> =
    this.extrasManagerStore.selectIsLastJourneyKeySelected$;

  analyticsUnsubscribe$ = new Subject<void>();

  isManageFlow: boolean = getObservableValueSync(
    this.store.select(BookingSelectors.selectIsBookingCommitted)
  );

  constructor(
    protected extrasManagerStore: ExtrasManagerStore,

    protected location: Location,
    protected ssrDataService: SsrDataService,
    protected store: Store,
    protected router: Router,
    protected pageBusyService: PageBusyService,
    protected tripDataService: TripDataService,
  ) {}

  ngOnInit() {
    this.extrasManagerStore.selectIsLastLegKeySelected$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(value => {
      this.isLastLeg = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
  async sellWrappers(): Promise<void>{
    this.pageBusyService.showLoadingSpinner();
    const wrapperChanges = getObservableValueSync(
      this.extrasManagerStore.selectWrapperChanges$
    );
    if (wrapperChanges.delete.length > 0) {
      await this.ssrDataService.removeSsrs(wrapperChanges.delete);
    }

    if (wrapperChanges.sell.length > 0) {
      var filteredSellKeys = wrapperChanges.sell.filter((value, index, array) => array.indexOf(value) === index);

      var sellRequest: SsrByKeyRequest[] = [];
      for (const key of filteredSellKeys) {
        var filteredChanges = wrapperChanges.sell.filter(wKey => wKey === key);
        var sellCount = filteredChanges.length;      
        sellRequest.push({count:sellCount, ssrKey:key, note: sellCount.toString()});
      }
      const request: SsrByKeysRequestv2 = {
        currencyCode: this.currencyCode,
        keys: sellRequest
      };
      await this.ssrDataService.sellSSrs(request);
    }
    this.pageBusyService.hideLoadingSpinner();
    this.location.back();
  }

  moveToNextJourney(): void {
    this.extrasManagerStore.moveToNextJourney();
    this.extrasManagerStore.moveToFirstPassenger();
  }

}
