import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-insurance-option',
  templateUrl: './ssr-select-insurance-layout.component.html',
  styleUrls: ['./ssr-select-insurance-layout.scss']
})
export class InsuranceOptionComponent {
  @Input() title!: string;
  @Input() price!: string;
  @Input() description!: string;
  @Input() selected: boolean = false;

  @Output() selectOption = new EventEmitter<void>();

  onSelect() {
    this.selectOption.emit();
  }
}
