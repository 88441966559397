import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { BaseComponentModule } from '../base-component.module';
import { CardsModule } from '../cards/cards.module';
import { CmsLabelModule } from '../cms/cms-components/label/cms-label.module';
import { CmsPromotionModule } from '../cms/cms-components/promotion/cms-promotion.module';
import { CmsWebCdkModule } from '../cms/cms-shared.module';
import { NavitaireDigitalCommonModule } from '../common/common.module';
import { FlightDetailsModule } from '../flight-select/flight-details/flight-details.module';
import { FlightSelectModule } from '../flight-select/flight-select.module';
import { NavitaireDigitalFormsModule } from '../forms/forms.module';
import { FlowBreadCrumbsModule } from '../layout/flow-bread-crumbs/flow-bread-cumbs.module';
import { HeaderModule } from '../layout/headers/header.module';
import { ModalModule } from '../layout/modal/modal.module';
import { ToastModule } from '../layout/toast/toast.module';
import { PassengerSeatsModule } from '../seatmap/passenger-seats/passenger-seats.module';
import { SeatMapModule } from '../seatmap/seatmap.module';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart/shopping-cart.module';
import { ExtrasComponentModule } from './extras/extras.component.module';
import { InBundleLabelComponent } from './in-bundle-label/index';
import { MealControlComponent } from './meal-control/meal-control.component';
import { SsrsPipesModule } from './pipes/index';
import { PlaneContainerComponent } from './plane-container/plane-container.component';
import { SeatInformationComponent } from './seat-information/seat-information.component';
import { SelectAdditionalSsrsComponent } from './select-additional-ssrs/select-additional-ssrs.component';
import { SelectSsrCardComponent } from './select-additional-ssrs/select-ssr-card/select-ssr-card.component';
import { SelectBagsComponent } from './select-bags/select-bags.component';
import { SelectInsuranceComponent } from './select-insurance/select-insurance.component';
import { SelectMealsComponent } from './select-meals/select-meals.component';
import { SelectSurprisesComponent } from './select-surprises/select-surprises.component';
import { SelectedFlightsModule } from './selected-flights/selected-flights.module';
import { SsrControlComponent } from './ssr-control/ssr-control.component';
import { SsrSelectLayoutComponent } from './ssr-select-layout/ssr-select-layout.component';
import { SsrsActionComponent } from './ssrs-action/ssrs-action.component';
import { SelectLoungeComponent } from './select-lounge/select-lounge.component';
import { SelectWrappersComponent } from './select-wrappers/select-wrappers.component';
import { SelectPassengerServiceComponent } from './select-passenger-service/select-passenger-service.component';
import { UnavailableSsrCardComponent } from './unavailable-ssr-card/unavailable-ssr-card.component';
import { InsuranceOptionComponent } from './ssr-select-insurance/ssr-select-insurance-layout.component';
import { InsuranceSelectionComponent } from './ssr-select-insurance-selection/ssr-select-insurance-selection-layout.component';


@NgModule({
  imports: [
    RouterModule,
    BaseComponentModule,
    NavitaireDigitalFormsModule,
    NavitaireDigitalCommonModule,
    CardsModule,
    SeatMapModule,
    ShoppingCartModule,
    FlowBreadCrumbsModule,
    PassengerSeatsModule,
    HeaderModule,
    FlightSelectModule,
    CmsWebCdkModule,
    FlightDetailsModule,
    ModalModule,
    ToastModule,
    SelectedFlightsModule,
    ExtrasComponentModule,
    CmsLabelModule,
    CmsPromotionModule,
    NgxUiLoaderModule,
    SsrsPipesModule
  ],
  declarations: [
    SsrSelectLayoutComponent,
    SelectBagsComponent,
    SelectMealsComponent,
    SelectSurprisesComponent,
    SsrControlComponent,
    SeatInformationComponent,
    PlaneContainerComponent,
    MealControlComponent,
    InBundleLabelComponent,
    SelectAdditionalSsrsComponent,
    SelectSsrCardComponent,
    SsrsActionComponent,
    SelectInsuranceComponent,
    SelectLoungeComponent,
    SelectWrappersComponent,
    SelectPassengerServiceComponent,
    UnavailableSsrCardComponent,
    InsuranceOptionComponent,
    InsuranceSelectionComponent
  ],
  exports: [
    SsrSelectLayoutComponent,
    SelectBagsComponent,
    SelectMealsComponent,
    SelectSurprisesComponent,
    SsrControlComponent,
    SeatInformationComponent,
    PlaneContainerComponent,
    MealControlComponent,
    InBundleLabelComponent,
    SelectAdditionalSsrsComponent,
    SelectSsrCardComponent,
    SsrsActionComponent,
    SelectInsuranceComponent,
    SelectLoungeComponent,
    SelectWrappersComponent,
    SelectPassengerServiceComponent,
    UnavailableSsrCardComponent,
    InsuranceOptionComponent,
    InsuranceSelectionComponent
  ]
})
export class ExtrasModule { }
