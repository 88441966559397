<div class="container">
  <div class="title">CITILINK GREENSALE​</div>
  <div class="sub-title">Find the perfect price for your flights by searching your destination and travel dates</div>

  <div class="row">
    <navitaire-digital-flight-search-multi
      [request]="request$"
      (requestChange)="handleRequestChanged($event)"
      class="flight-search"
    >
      <!-- <navitaire-digital-promotion-search></navitaire-digital-promotion-search> -->
    </navitaire-digital-flight-search-multi>
  </div>
</div>
