import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  Injector,
  isDevMode,
  NgZone,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiErrors,
  ApiMessages,
  NavigationTags,
  SessionDataService
} from '@navitaire-digital/web-data-4.5.0';
import { BaseAppAnalyticsService } from '../analytics/app-analytics.interface';
import { WindowRefService } from '../common/window-ref.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  warnings: any[] = [];
  errors: any[] = [];
  errorShown: boolean = false;

  // use injector to get instance of Router to avoid cyclic deps in provider resolution in app module
  get router(): Router {
    return this.injector.get(Router);
  }
  get sessionDataService(): SessionDataService {
    return this.injector.get(SessionDataService);
  }
  get appAnalyticsService(): BaseAppAnalyticsService {
    return this.injector.get(BaseAppAnalyticsService);
  }

  constructor(
    protected injector: Injector,
    protected zone: NgZone,
    @Inject(PLATFORM_ID) protected platformId: Object,
    @Optional() protected windowRefService: WindowRefService
  ) {}

  handleError(error: Error | HttpErrorResponse | any): void {
    this.appAnalyticsService.trackError('GlobalErrorHandler', {
      error: error
    });
    // convert promise rejection error

    if(this.excludeThisError(error.rejection.url)) return;

    if (
      error.rejection instanceof HttpErrorResponse ||
      error.rejection instanceof ApiErrors ||
      error.rejection instanceof ApiMessages
    ) {
      error = error.rejection;
    } else if (error.rejection && error.rejection.innerException) {
      error = error.rejection.innerException;
    }

    if (this.isWarning(error)) {
      this.warnings.push(error);
      return;
    }
    this.errors.push(error);
    console.error('Error: ', error);

    if (isDevMode() && !this.errorShown) {
      let errorSummary = 'type: Angular';
      if (error instanceof HttpErrorResponse) {
        errorSummary = 'type: ' + error.status;
      } else if (error instanceof ApiMessages) {
        const firstError = error.messages[0];
        errorSummary = 'type: ApiMessage code: ' + firstError.code;
      } else if (error instanceof ApiErrors) {
        const firstError = error.errors[0];
        errorSummary = 'type: ApiError code: ' + firstError.code;
      }

      this.errorShown = true;

      if (
        isPlatformBrowser(this.platformId) &&
        this.windowRefService?.window?.confirm &&
        !this.windowRefService?.window?.confirm(
          '(Dev Mode) Uncaught Global Error:\n' +
            errorSummary +
            '\n\nRedirect to the error page (Default Behavior)?'
        )
      ) {
        return;
      } else {
        this.navigateToErrorPage();
      }
    }

    if (!isDevMode()) {
      this.navigateToErrorPage();
    }
  }

  excludeThisError(url: string){
    const arrayToExclude = [{data: 'availability/lowfare'}];
    const isExist =  arrayToExclude.some(e => url.includes(e.data))
   
    return isExist;
  }


  isWarning(error: Error | HttpErrorResponse | any): boolean {
    if (error instanceof ApiErrors) {
      const firstError = error.errors[0];
      const url = this.router.url;
      if (firstError.statusCode === 440 && url.includes('/home')) {
        console.warn('Token expired');
        this.sessionDataService.newSession({ applicationName: 'IBE' });
        return true;
      }
    } else if (error instanceof HttpErrorResponse) {
      // This endpoint / functionality is not needed for a successful booking.
      if (error.url && error.url.includes('/lowfare/estimate')) {
        console.warn('Low fare estimate not available ' + error.url);
        return true;
      }
    }

    return false;
  }

  getLastError(): HttpErrorResponse | ApiMessages | ApiErrors | Error | null {
    if (!this.errors || this.errors.length === 0) {
      return null;
    }

    return this.errors[this.errors.length - 1];
  }

  // Redirect to error page
  navigateToErrorPage(): void {
    this.zone.run(() =>
      this.router.navigate(['/error/global'], {
        state: {
          tag: NavigationTags.GlobalErrorHandlerControlled
        }
      })
    );
  }

  showErrorDialog(
    code: string,
    title: string,
    message: string,
    error: Error
  ): void {
    alert(message);
  }
}
