import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  Booking,
  isFutureJourney,
  Journey,
  TripStatusv2
} from '@navitaire-digital/nsk-api-4.5.0';
import {
  BookingSelectors,
  LegDetailsDataService,
  NskFlightStatusSelectors
} from '@navitaire-digital/web-data-4.5.0';
import { Store } from '@ngrx/store';
import type { Dictionary } from 'lodash';
import { filter, Observable, take } from 'rxjs';
import { MyTripsService } from '../my-trips.service';
import { getObservableValueSync } from '@navitaire-digital/clients-core';
import { selectflightReserveCancelDepartureTimeLimitSetting } from '../../store/selectors';
import { QGSettingsDataService } from '@customer/extensions';
import {
  ClearSelectedPaymentMethod,
  SetflightReserveCancelDepartureTimeLimitSetting
} from '../../store/actions';
import { isJourneyManageable } from '../utilities/is-journey-manageable';
import { Router } from '@angular/router';

@Component({
  selector: 'navitaire-digital-my-booking',
  templateUrl: './my-booking.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['my-booking.scss']
})
export class MyBookingComponent implements OnInit {
  /** Current booking in state */
  booking$: Observable<Booking> = this.store.select(
    BookingSelectors.selectBooking
  );

  /** this holds the journey keys that are allowed to be managed */
  manageableJourneyKeys: string[] = [];

  legDetails$: Observable<Dictionary<TripStatusv2>> = this.store.select(
    NskFlightStatusSelectors.selectLegDetails
  );

  @Output() navigateToManage: EventEmitter<void> = new EventEmitter();
  @Output() navigateToCheckin: EventEmitter<void> = new EventEmitter();
  @Output() navigateToChangeFlight: EventEmitter<void> = new EventEmitter();
  @Output() showMangeNotAllowedModal: EventEmitter<void> =
    new EventEmitter<void>();

  allowManage: boolean = false;

  constructor(
    protected myTripsService: MyTripsService,
    protected legDetailsDataService: LegDetailsDataService,
    protected qgSettingDataService: QGSettingsDataService,
    protected store: Store,
    protected router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.store.dispatch(ClearSelectedPaymentMethod());

    this.booking$
      .pipe(
        filter(booking => !!booking),
        take(1)
      )
      .subscribe(async booking => {
        const legKeys = booking.journeys.reduce((keys, journey) => {
          return [
            ...keys,
            ...journey.segments.reduce((lKeys, segment) => {
              return [...lKeys, ...segment.legs.map(leg => leg.legKey)];
            }, [])
          ];
        }, []);
        await this.legDetailsDataService.retrieveLegDetails(legKeys);
      });

    // Checks if flight Reserve Cancel Departure Time Limit setting is stored.
    const flightReserveCancelDepartureTimeLimitSetting = getObservableValueSync(
      this.store.select(selectflightReserveCancelDepartureTimeLimitSetting)
    );

    if (!flightReserveCancelDepartureTimeLimitSetting) {
      const cancelDepartureLimitSetting =
        await this.qgSettingDataService.getFlightReserveCancelDepartureTimeLimitSetting();

      this.store.dispatch(
        SetflightReserveCancelDepartureTimeLimitSetting({
          flightReserveCancelDepartureTimeLimitSetting:
            cancelDepartureLimitSetting
        })
      );
    }

    // Gets the journey keys of journeys that are allowed to be managed.
    const bookings = getObservableValueSync(this.booking$);
    const duration = getObservableValueSync(
      this.store.select(selectflightReserveCancelDepartureTimeLimitSetting)
    );
    bookings?.journeys?.forEach(journey => {
      if (isJourneyManageable(journey, duration)) {
        this.manageableJourneyKeys.push(journey?.journeyKey);
      }
    });
  }

  /** Select journey to manage and emit to navigate to hub page */
  async selectTrip(journey: Journey): Promise<void> {
      
    //check checkedIn Pax
    if(!this.myTripsService.isHasPaxToManage(journey)){
      this.navigateToItinerary();
      return;
    }

    //temporary close this method
    // can't select departed/past journeys or journeys that are not allowed to be managed
    if (
      !isFutureJourney(journey) ||
      !this.manageableJourneyKeys.includes(journey?.journeyKey)
    ) {
      return;
    }
    await this.myTripsService.selectJourneyToMange(journey);

    //#region temporary close this method for restriction in MMB
    // check if trip is allowed to be managed
    // temporary not allowed
    // this.allowManage = await this.myTripsService.isManageFlowAllowed();
    // if (!this.allowManage) {
    //   this.showMangeNotAllowedModal.emit();
    //   return;
    // }
    //#endregion
    this.navigateToManage.emit();
  }
  

  /** Select journey to checkin and emit to navigate to checkin page */
  async selectCheckin(journey: Journey): Promise<void> {
    await this.myTripsService.selectJourneyToMange(journey);
    this.navigateToCheckin.emit();
  }

  /** Select journey to change and emit to navigate to change flight flow */
  async selectChangeFlight(journey: Journey): Promise<void> {
    await this.myTripsService.selectJourneyToMange(journey);
    this.navigateToChangeFlight.emit();
  }

  async navigateToItinerary(): Promise<void> {
    await this.router.navigate(['manage/itinerary']);
  }

  async navigateToManageHub(): Promise<void>{
    await this.router.navigate(['manage/hub']);
  }
}
